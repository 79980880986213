import "./TransactionsList.scss";
import { shortAddress } from "../../Helpers/Helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getContract, getTransactions, mapEvents } from "../../API/ContractAPI";
import { useWeb3React } from "@web3-react/core";

const TransactionsList = ({lastSend}) => {
    const { library } = useWeb3React();
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setProgress] = useState(0);
    const [loader, setLoader] = useState({id: 0});

    const getTransactionsHandler = useCallback(async () => {
        loader.id = loader.id + 1;
        setIsLoading(true);
        getTransactions(library, setTransactions, setIsLoading, setProgress, loader);
    }, [library]);

    useEffect(() => {
        getTransactionsHandler();
    }, [library, lastSend, getTransactionsHandler]);

    useEffect(() => {
        const contract = getContract();

        contract.on("Staked", getTransactionsHandler)
        contract.on("Claimed", getTransactionsHandler)

        return () => {
            contract.off("Claimed", getTransactionsHandler)
            contract.off("Staked", getTransactionsHandler)
        }
    }, [getTransactionsHandler])

    const filtered = useMemo(() => {
        const res = mapEvents(transactions)
        return res;
    }, [transactions])

    return (
        <div className="transactions_container">
            {isLoading && (
                <div
                    className="transactions_loading"
                    style={{
                        '--progress': `${(loadingProgress*100).toFixed(0)}%`
                    }}
                >
                    <span>
                        Загрузка: {(loadingProgress*100).toFixed(0)}%
                    </span>
                </div>
            )}

            <table>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Адрес кошелька</th>
                        <th>Кол-во токенов</th>
                        <th>Срок стекинга</th>
                        <th className="button_column">Транзакция</th>
                    </tr>
                </thead>
                <tbody>
                    {filtered.map((tx, index) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <a
                                        href={process.env.REACT_APP_TRANSACTION_ADRESS_BASE + tx.address}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                    >
                                        {shortAddress(tx.address)}
                                    </a>
                                </td>
                                <td>{tx.count}</td>
                                <td>{tx.endDate}</td>
                                <td>
                                    <a
                                        href={process.env.REACT_APP_TRANSACTION_TX_BASE + tx.txHash}
                                        target={"_blank"}
                                        rel={"noreferrer"}
                                        className={"btn"}
                                    >
                                        Ссылка на транзакцию
                                    </a>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionsList;
