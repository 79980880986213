import ABI from "../ABI/ContractABI.json";
import { BigNumber, ethers } from "ethers";

const regroupArray = (array, groupBy) => {
    const repeats = Math.ceil(array.length / groupBy);
    let res = [];

    for(let i = 0; i < repeats; i++) {
        res[i] = [];
        for(let j = 0; j < groupBy; j++) {
            if(array[i*repeats + j]) {
                res[i].push(array[i*repeats + j])
            }
        }
    }

    return res;
}

export const getContract = (library) => {
    console.log(library);
    const provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_SCAN_LINK
    );
    
    const signer = (library || provider).getSigner().connectUnchecked();
    const contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_ADDRESS, ABI, signer);
    return contract;
};

export const sendTokens = async (library, address, count, term) => {
    try {
        const contract = getContract(library);

        console.log(count, BigNumber.from(count).mul(BigNumber.from('1000000000000000000')).toBigInt());

        const tx = await contract.stake(
            address,
            BigNumber.from(count).mul(BigNumber.from('1000000000000000000')).toBigInt(),
            term,
            {
                gasLimit: 2500000
            }
        );
        await tx.wait();

        return { ok: true, message: "Успех" };
    } catch (e) {
        console.log(e.message);
        return { ok: false, message: "Ошибка" };
    }
};

export const mapEvents = (events) => {
    const AllEvents = events.filter(event => event.event === 'Staked' || event.event === 'Claimed');

    //console.log(AllEvents);

    const StakedEvents = AllEvents.filter(event => event.event === 'Staked');
    const ClaimEvents = AllEvents.filter(event => event.event === 'Claimed');

    const filteredStakedEvents = StakedEvents.filter((event) => {
        return !ClaimEvents.some((claimEvent) => {
            const claimID = BigNumber.from(claimEvent.args.id).toBigInt();
            const stackID = BigNumber.from(event.args.id).toBigInt();

            const receiverID = BigNumber.from(claimEvent.args.receiver).toBigInt();
            const ownerID = BigNumber.from(event.args.receiver).toBigInt();

            return claimID === stackID &&
            receiverID === ownerID
        })
    })

    //console.log(filteredStakedEvents);

    if (AllEvents && AllEvents.length > 0) {
        return filteredStakedEvents.map(event => {
            return {
                id: event.args.id.toString(),
                address: event.args.owner,
                count: ethers.utils.formatUnits(event.args.amount, process.env.REACT_APP_TOKEN_DECIMALS),
                endDate: new Date(event.args.expiredTime * 1000).toLocaleString(),
                txHash: event.transactionHash
            }
        });
    }

    return [];
}

export const getTransactions = async (library, setTransactions, setIsLoading, setProgress, loader) => {
    try {
        const _loaderID = loader.id;

        const contract = getContract(library);
        const filter = {};
        let events;

        if(+process.env.REACT_APP_BLOCK_SIZE > 0) {
            setTransactions(() => []);
            let sections = [];
            let offset = 0;
            const sectionLength = 2000;
            while(offset < process.env.REACT_APP_BLOCK_SIZE) {
                sections.push([offset, offset + sectionLength])
                offset = Math.min(process.env.REACT_APP_BLOCK_SIZE, offset + sectionLength);
            }
            const groups = regroupArray(sections, 40);
    
            const block = await contract.provider.getBlockNumber();
            const tasks = groups.map((group) => {
                return () => {
                    return Promise.all(group.map((section) => {
                        const [from, to] = section;

                        //console.log(section)

                        return contract.queryFilter(filter, block - to, block - from);
                    }))
                }
            });

            let iteration = 1;
            events = [];
            for (const fn of tasks) {
                if(loader.id !== _loaderID) {break;}

                console.log(`[loaderID: ${loader.id}] call ${iteration} of ${tasks.length} (${iteration/tasks.length*100}%)`)
                const res = await fn();
                iteration++;

                setTransactions((oldstate) => {
                    return [...oldstate, ...res.flat(1)]
                })
                setProgress(iteration/tasks.length)
            }

            if(loader.id === _loaderID) {
                setIsLoading(false);
            }
        } else {
            events = await contract.queryFilter(filter);

            setTransactions(mapEvents(events));
            setIsLoading(false);
        }
        
    } catch (e) {
        console.log(e.message);
    }
};
