import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import { ethers } from "ethers";

const root = ReactDOM.createRoot(document.getElementById("root"));

const getLibrary = (provider) => {
    console.log('test')
    const library = new ethers.providers.Web3Provider(provider);
    library.pollingInterval = 15000;
    return library;
}

root.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App />
    </Web3ReactProvider>
);
